module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"modinfinity","short_name":"Modinfinity","start_url":"/","background_color":"#5561D0","theme_color":"#5561D0","display":"minimal-ui","icon":"src/images/Favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"897ca55b31b28113ed02e07dc991c0ec"},
    },{
      plugin: require('../node_modules/gatsby-plugin-fathom/gatsby-browser.js'),
      options: {"plugins":[],"trackingUrl":"wombat.modinfinity.com","siteId":"SGIEJDBU","embedVersion":"v2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
